import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCart } from '../context/CartContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f2f5;
    min-height: 100vh;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Textarea = styled.textarea`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: none;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const CancelButton = styled(Button)`
    background-color: #ff4d4d;
    margin-top: 10px;

    &:hover {
        background-color: #ff1a1a;
    }
`;

const Message = styled.h1`
    color: #333;
    margin-bottom: 20px;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
`;

const RadioContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
`;

const RadioLabel = styled.label`
    display: flex;
    align-items: center;
`;

const RadioInput = styled.input`
    margin-right: 5px;
`;

const Checkout = () => {
    const { cart, totalAmount } = useCart(); // useCart 사용
    const { state } = useLocation();
    const { minimumOrderAmount, deliveryFee } = state;
    const [isValidOrder, setIsValidOrder] = useState(false);
    const [address, setAddress] = useState(localStorage.getItem('user_address') || '');
    const [ownerRequest, setOwnerRequest] = useState('');
    const [deliveryRequest, setDeliveryRequest] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('credit_card');
    const navigate = useNavigate(); // useNavigate 사용

    useEffect(() => {
        setIsValidOrder(totalAmount >= minimumOrderAmount);
    }, [totalAmount, minimumOrderAmount]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValidOrder) {
            alert('결제가 완료되었습니다.');
            window.location.href = '/';
        }
    };

    return (
        <Container>
            <Message>결제 페이지</Message>
            <Form onSubmit={handleSubmit}>
                <Label>배달 주소</Label>
                <Input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                <Label>사장님께 요청 사항</Label>
                <Textarea
                    value={ownerRequest}
                    onChange={(e) => setOwnerRequest(e.target.value)}
                    rows="3"
                />
                <Label>배달 기사님께 요청 사항</Label>
                <Textarea
                    value={deliveryRequest}
                    onChange={(e) => setDeliveryRequest(e.target.value)}
                    rows="3"
                />
                <Label>결제 수단</Label>
                <RadioContainer>
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name="paymentMethod"
                            value="credit_card"
                            checked={paymentMethod === 'credit_card'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        신용카드
                    </RadioLabel>
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name="paymentMethod"
                            value="bank_transfer"
                            checked={paymentMethod === 'bank_transfer'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        계좌결제
                    </RadioLabel>
                    <RadioLabel>
                        <RadioInput
                            type="radio"
                            name="paymentMethod"
                            value="cash"
                            checked={paymentMethod === 'cash'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        현금결제
                    </RadioLabel>
                </RadioContainer>
                <h3>총 금액: {totalAmount + deliveryFee}원</h3>
                <Button type="submit">결제하기</Button>
                <CancelButton type="button" onClick={() => navigate(-1)}>취소하기</CancelButton>
            </Form>
        </Container>
    );
};

export default Checkout;
