import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
    min-height: 100vh;
`;

const ReviewContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    margin-bottom: 20px;
`;

const ReviewItem = styled.div`
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
`;

const ReviewContent = styled.p`
    margin: 5px 0;
`;

const ReviewRating = styled.span`
    font-weight: bold;
    color: #007bff;
`;

const StoreName = styled.h3`
    margin: 0;
    padding-bottom: 10px;
`;

function UserReviews() {
    const { userId } = useParams();
    const [reviews, setReviews] = useState([]);
    const [storeNames, setStoreNames] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserReviews = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_ROOT +`/api/review/user/${userId}`);
                if (response.data.result.result_code === 200) {
                    setReviews(response.data.body);
                    fetchStoreNames(response.data.body);
                } else {
                    setError('리뷰 정보를 불러오는데 실패했습니다.');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user reviews:', error);
                setError('리뷰 정보를 불러오는데 실패했습니다.');
                setLoading(false);
            }
        };

        const fetchStoreNames = async (reviews) => {
            const storeNamePromises = reviews.map(review =>
                axios.get(`${process.env.REACT_APP_URL}/api/store/${review.store_id}`)
            );

            try {
                const storeResponses = await Promise.all(storeNamePromises);
                const storeNames = storeResponses.reduce((acc, response, index) => {
                    if (response.data.result.result_code === 200) {
                        acc[reviews[index].store_id] = response.data.body.name;
                    } else {
                        acc[reviews[index].store_id] = 'Unknown Store';
                    }
                    return acc;
                }, {});
                setStoreNames(storeNames);
            } catch (error) {
                console.error('Error fetching store names:', error);
                setError('가게 이름을 불러오는데 실패했습니다.');
            }
        };

        fetchUserReviews();
    }, [userId]);

    if (loading) return <Container>로딩중...</Container>;
    if (error) return <Container>{error}</Container>;

    return (
        <Container>
            <h1>User Reviews</h1>
            {reviews.length === 0 ? (
                <p>작성한 리뷰가 없습니다.</p>
            ) : (
                reviews.map((review, index) => (
                    <ReviewContainer key={index}>
                        <StoreName>{storeNames[review.store_id] || 'Loading...'}</StoreName>
                        <ReviewItem>
                            <ReviewRating>별점: {review.star}</ReviewRating>
                            <ReviewContent>{review.content}</ReviewContent>
                            <small>{new Date(review.created_at).toLocaleString()}</small>
                        </ReviewItem>
                    </ReviewContainer>
                ))
            )}
        </Container>
    );
}

export default UserReviews;
