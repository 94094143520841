import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Select = styled.select`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const Message = styled.p`
    margin-top: 10px;
    color: ${props => (props.error ? 'red' : 'green')};
`;

const Label = styled.p`
    margin: 0;
    color: #666;
    font-size: 14px;
`;

function UpdateStore() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [store, setStore] = useState({
        id: id,
        name: '',
        address: '',
        store_category: '가게 카테고리',
        thumbnail_url: '',
        minimum_amount: 0,
        minimum_delivery_amount: 0,
        phone_number: ''
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchStoreDetails = async () => {
            try {
                const response = await axios.get(`f/api/store/${id}`);
                if (response.data.result.result_code === 200) {
                    setStore(response.data.body);
                } else {
                    setMessage('가게 정보를 불러오는데 실패했습니다.');
                    setError(true);
                }
            } catch (error) {
                console.error('Error fetching store details:', error);
                setMessage('가게 정보를 불러오는데 실패했습니다.');
                setError(true);
            }
        };

        fetchStoreDetails();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStore(prevStore => ({
            ...prevStore,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requestBody = {
            result: {
                resultCode: 0,
                resultMessage: '',
                resultDescription: ''
            },
            body: store
        };

        try {
            const response = await axios.put(process.env.REACT_APP_API_ROOT +`/open-api/store/update/${id}`, requestBody);

            if (response.data.result.result_code === 200) {
                alert('가게 정보 수정이 성공적으로 완료되었습니다!');
                navigate(`/store/${id}`);
            } else {
                setMessage(response.data.result.result_message);
                setError(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('가게 정보 수정 중 오류가 발생했습니다.');
            setError(true);
        }
    };

    return (
        <Container>
            <h1>가게 정보 수정</h1>
            <Form onSubmit={handleSubmit}>
                <Input type="text" name="name" value={store.name} onChange={handleChange} placeholder="가게 이름" required />
                <Input type="text" name="address" value={store.address} onChange={handleChange} placeholder="주소" required />
                <Select name="store_category" value={store.store_category} onChange={handleChange}>
                    <option value="가게 카테고리" disabled>선택 필수</option>
                    <option value="CHINESE_FOOD">중식</option>
                    <option value="WESTERN_FOOD">양식</option>
                    <option value="KOREAN_FOOD">한식</option>
                    <option value="JAPANESE_FOOD">일식</option>
                    <option value="CHICKEN">치킨</option>
                    <option value="PIZZA">피자</option>
                    <option value="HAMBURGER">햄버거</option>
                    <option value="COFFEE_TEA">카페</option>
                </Select>
                <Input type="text" name="thumbnail_url" value={store.thumbnail_url} onChange={handleChange} placeholder="썸네일 URL" required />
                <Label>최소 주문 금액</Label>
                <Input type="number" name="minimum_amount" value={store.minimum_amount} onChange={handleChange} placeholder="최소 금액" required />
                <Label>배달 비용</Label>
                <Input type="number" name="minimum_delivery_amount" value={store.minimum_delivery_amount} onChange={handleChange} placeholder="최소 배달 금액" required />
                <Input type="text" name="phone_number" value={store.phone_number} onChange={handleChange} placeholder="전화번호" required />
                <Button type="submit">가게 정보 수정</Button>
            </Form>
            {message && <Message error={error}>{message}</Message>}
        </Container>
    );
}

export default UpdateStore;
