import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
`;

const StoreListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 80%;
`;

const StoreItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`;

const StoreDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    flex: 1;
`;

const StoreName = styled.span`
    font-weight: bold;
`;

const StoreCategory = styled.span`
    color: #888;
`;

const StoreAddress = styled.span`
    color: #555;
`;

const StoreStar = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
`;

const Star = styled.span`
    color: gold;
    margin-right: 2px;
`;

const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 80%;
`;

const Select = styled.select`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Input = styled.input`
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Thumbnail = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
`;

const categoryMapping = {
    CHINESE_FOOD: "중식",
    WESTERN_FOOD: "양식",
    KOREAN_FOOD: "한식",
    JAPANESE_FOOD: "일식",
    CHICKEN: "치킨",
    PIZZA: "피자",
    HAMBURGER: "햄버거",
    COFFEE_TEA: "카페"
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function StoreList() {
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [category, setCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        const initialCategory = query.get('category') || '';
        setCategory(initialCategory);
        fetchStores(initialCategory, '');
    }, []);

    const fetchStores = (category, searchTerm) => {
        setLoading(true);
        const url = category ? process.env.REACT_APP_API_ROOT + `/api/store/search?storeCategory=${category}` : process.env.REACT_APP_API_ROOT + `/api/store/list`;
        axios.get(url)
            .then(response => {
                if (response.data.result.result_code === 200) {
                    let filteredStores = response.data.body;
                    if (searchTerm) {
                        filteredStores = filteredStores.filter(store =>
                            store.name.toLowerCase().includes(searchTerm.toLowerCase())
                        );
                    }
                    setStores(filteredStores);
                    //window.location.reload();

                } else {
                    setError('데이터를 불러오는데 실패했습니다.');
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching store list:', error);
                setError('데이터를 불러오는데 실패했습니다.');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchStores(category, searchTerm);
    }, [category]);

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        window.location.reload();
    };

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchStores(category, searchTerm);
            window.location.reload();
        }
        window.location.reload();
    };

    const renderStars = (rating) => {
        const roundedRating = Math.round(rating * 2) / 2;
        const fullStars = Math.floor(roundedRating);
        const halfStar = roundedRating % 1 >= 0.5 ? 1 : 0;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<Star key={`full-${i}`}>⭐</Star>);
        }

        if (halfStar) {
            stars.push(<Star key="half">✩</Star>);
        }

        return stars;
    };

    if (loading) return <Container>로딩중...</Container>;
    if (error) return <Container>{error}</Container>;

    return (
        <Container>
            <h1>{category ? categoryMapping[category] : '전체'}</h1>
            <FilterContainer>
                <Input
                    type="text"
                    placeholder="가게 이름 검색"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onKeyDown={handleSearchKeyDown}
                />
            </FilterContainer>
            <StoreListContainer>
                {stores.map(store => (
                    <StoreItem key={store.id} onClick={() => navigate(`/store/${store.id}`)}>
                        <Thumbnail src={store.thumbnail_url} alt={store.name}/>
                        <StoreDetails>
                            <StoreName>{store.name}</StoreName>
                            <StoreCategory>{categoryMapping[store.category]}</StoreCategory>
                            <StoreAddress>{store.address}</StoreAddress>
                            <StoreStar>
                                {renderStars(store.star)}
                                {store.star ? ` (${store.star.toFixed(1)}점)` : 'N/A'}
                            </StoreStar>
                        </StoreDetails>
                    </StoreItem>
                ))}
            </StoreListContainer>
        </Container>
    );
}

export default StoreList;
