import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
`;

const InfoContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
`;

const InfoItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.button`
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

function UserInfo() {
    const [userInfo, setUserInfo] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const email = localStorage.getItem('user_email');
                if (!email) {
                    setErrorMessage('유저 이메일이 저장되어 있지 않습니다.');
                    return;
                }

                const encodedEmail = encodeURIComponent(email);
                console.log(`Fetching user info for encoded email: ${encodedEmail}`); // 디버깅을 위한 콘솔 출력

                const token = localStorage.getItem('access_token');
                const response = await axios.get(process.env.REACT_APP_API_ROOT +`/api/user/me?email=${encodedEmail}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserInfo(response.data.body);
            } catch (error) {
                console.error('Error fetching user info:', error);
                setErrorMessage('유저 정보를 가져오는 중 오류가 발생했습니다.');
            }
        };

        fetchUserInfo();
    }, []);

    const handleViewReviews = () => {
        if (userInfo) {
            navigate(`/user-reviews/${userInfo.id}`);
        }
    };

    return (
        <Container>
            <h1>User Info</h1>
            {errorMessage ? (
                <p>{errorMessage}</p>
            ) : (
                userInfo && (
                    <InfoContainer>
                        <InfoItem><strong>이름:</strong> {userInfo.name}</InfoItem>
                        <InfoItem><strong>이메일:</strong> {userInfo.email}</InfoItem>
                        <InfoItem><strong>주소:</strong> {userInfo.address}</InfoItem>
                        <InfoItem><strong>가입일:</strong> {new Date(userInfo.registered_at).toLocaleString()}</InfoItem>
                        <Button onClick={handleViewReviews}>작성 리뷰 확인</Button>
                    </InfoContainer>
                )
            )}
        </Container>
    );
}

export default UserInfo;
