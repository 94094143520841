import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCart } from '../context/CartContext';

const Container = styled.div`
    display: flex;
    padding: 20px;
    background-color: #f0f2f5;
`;

const MainContent = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StoreInfo = styled.div`
    width: 80%;
    background: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Tabs = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const Tab = styled.button`
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: ${props => (props.active ? '#007bff' : '#ddd')};
    color: ${props => (props.active ? 'white' : 'black')};
    cursor: pointer;
    font-size: 16px;
`;

const StoreMenu = styled.div`
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-bottom: 1px solid #ccc;
`;

const MenuItemName = styled.span`
    font-weight: bold;
`;

const MenuItemPrice = styled.span`
    color: #888;
`;

const MenuItemThumbnail = styled.img`
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
`;

const Thumbnail = styled.img`
    max-width: 100%;
    height: 100px;
    border-radius: 10px;
    margin-top: 10px;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const MenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CartContainer = styled.div`
    flex: 1;
    background: white;
    padding: 20px;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CartItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-bottom: 1px solid #ccc;
`;

const CartItemName = styled.span`
    font-weight: bold;
`;

const CartItemPrice = styled.span`
    color: #888;
`;

const RemoveButton = styled(Button)`
    background-color: #ff4d4d;

    &:hover {
        background-color: #ff1a1a;
    }
`;

const EmptyCartMessage = styled.p`
    color: #888;
    font-size: 18px;
    text-align: center;
`;

const ReviewContainer = styled.div`
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ReviewHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const ReviewItem = styled.div`
    padding: 10px;
    border-bottom: 1px solid #ccc;
`;

const ReviewContent = styled.p`
    margin: 5px 0;
`;

const ReviewRating = styled.div`
    font-weight: bold;
    color: #007bff;
`;

const ReviewForm = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

const ReviewInput = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
`;

const StarInput = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const ReviewButton = styled(Button)`
    background-color: #28a745;

    &:hover {
        background-color: #218838;
    }
`;

const Star = styled.span`
    color: gold;
    margin-right: 2px;
`;

function StoreDetail() {
    const { id } = useParams();
    const navigate = useNavigate(); // useNavigate 추가
    const [store, setStore] = useState(null);
    const [menu, setMenu] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [activeTab, setActiveTab] = useState('menu'); // 탭 상태 추가
    const { cart, addToCart, removeFromCart, totalAmount } = useCart(); // useCart 사용
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newReview, setNewReview] = useState({ star: '', content: '' });

    useEffect(() => {
        const fetchStoreDetails = async () => {
            try {
                const [storeResponse, menuResponse] = await Promise.all([
                    axios.get(process.env.REACT_APP_API_ROOT + `/api/store/${id}`),
                    axios.get(process.env.REACT_APP_API_ROOT +`/api/store-menu/search?storeId=${id}`)
                ]);

                if (storeResponse.data.result.result_code === 200) {
                    setStore(storeResponse.data.body);
                } else {
                    setError('가게 정보를 불러오는데 실패했습니다.');
                }

                if (menuResponse.data.result.result_code === 200) {
                    setMenu(menuResponse.data.body);
                } else {
                    setError('메뉴 정보를 불러오는데 실패했습니다.');
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('데이터를 불러오는데 실패했습니다.');
                setLoading(false);
            }
        };

        fetchStoreDetails();
    }, [id]);

    const fetchReviews = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_ROOT + `/api/review/store/${id}`);
            if (response.data.result.result_code === 200) {
                setReviews(response.data.body);
            } else {
                setError('리뷰 정보를 불러오는데 실패했습니다.');
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
            setError('리뷰 정보를 불러오는데 실패했습니다.');
        }
    };

    const handleCheckout = () => {
        const effectiveAmount = totalAmount - store.minimum_delivery_amount;
        if (effectiveAmount < store.minimum_amount) {
            alert("최소 주문 금액을 맞춰주세요!");
        } else {
            navigate('/checkout', {
                state: {
                    minimumOrderAmount: store.minimum_amount,
                    deliveryFee: store.minimum_delivery_amount
                }
            });
        }
    };

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('user_id');
        const reviewData = {
            store_id: id,
            user_id: userId,
            star: parseFloat(newReview.star),
            content: newReview.content
        };

        try {
            const response = await axios.post(process.env.REACT_APP_API_ROOT + `/api/review/register`, reviewData);
            if (response.data.result.result_code === 200) {
                alert('리뷰가 성공적으로 등록되었습니다.');
                setNewReview({ star: '', content: '' });
                await fetchReviews();
                updateAverageStar();
            } else {
                alert('리뷰 등록에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            alert('리뷰 등록 중 오류가 발생했습니다.');
        }
    };

    const updateAverageStar = async () => {
        const averageStar = (reviews.reduce((acc, review) => acc + review.star, 0) + parseFloat(newReview.star)) / (reviews.length + 1);
        const roundedAverageStar = Math.round(averageStar * 10) / 10;

        try {
            await axios.put(process.env.REACT_APP_API_ROOT +`/api/store/set-star?id=${id}&star=${roundedAverageStar}`);
            setStore({ ...store, star: roundedAverageStar });
        } catch (error) {
            console.error('Error updating average star:', error);
            alert('별점 업데이트 중 오류가 발생했습니다.');
        }
    };

    const renderStars = (rating) => {
        const roundedRating = Math.round(rating * 2) / 2; // 반올림하여 0.5 단위로 만듭니다
        const fullStars = Math.floor(roundedRating);
        const halfStar = roundedRating % 1 >= 0.5 ? 1 : 0;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<Star key={`full-${i}`}>⭐</Star>);
        }

        if (halfStar) {
            stars.push(<Star key="half">✩</Star>);
        }

        return stars;
    };

    const renderContent = () => {
        if (activeTab === 'menu') {
            return (
                <StoreMenu>
                    <MenuHeader>
                        <h2>메뉴</h2>
                        <Button onClick={() => navigate(`/store/${id}/add-menu`)}>메뉴 등록</Button>
                    </MenuHeader>
                    {menu.map(item => (
                        <MenuItem key={item.id}>
                            <MenuItemThumbnail src={item.thumbnail_url} alt={item.name} />
                            <MenuItemName>{item.name}</MenuItemName>
                            <MenuItemPrice>{item.amount}원</MenuItemPrice>
                            <Button onClick={() => addToCart(item)}>장바구니에 추가</Button>
                        </MenuItem>
                    ))}
                </StoreMenu>
            );
        } else if (activeTab === 'reviews') {
            return (
                <ReviewContainer>
                    <ReviewHeader>
                        <h2>리뷰 ({reviews.length}개)</h2>
                    </ReviewHeader>
                    {reviews.map((review, index) => (
                        <ReviewItem key={index}>
                            <ReviewRating>{renderStars(review.star)} ({review.star.toFixed(1)}점)</ReviewRating>
                            <ReviewContent>{review.content}</ReviewContent>
                            <small>{review.created_at}</small>
                        </ReviewItem>
                    ))}
                    <ReviewForm onSubmit={handleReviewSubmit}>
                        <StarInput
                            type="number"
                            min="1"
                            max="5"
                            step="0.5"
                            placeholder="별점 (1-5) ,0.5점 단위로 올림됩니다."
                            value={newReview.star}
                            onChange={(e) => setNewReview({ ...newReview, star: e.target.value })}
                            required
                        />
                        <ReviewInput
                            rows="4"
                            placeholder="리뷰 내용 (선택사항)"
                            value={newReview.content}
                            onChange={(e) => setNewReview({ ...newReview, content: e.target.value })}
                        />
                        <ReviewButton type="submit">리뷰 등록</ReviewButton>
                    </ReviewForm>
                </ReviewContainer>
            );
        }
    };

    return (
        <Container>
            <MainContent>
                {loading ? (
                    <Container>로딩중...</Container>
                ) : error ? (
                    <Container>{error}</Container>
                ) : (
                    <>
                        {store && (
                            <StoreInfo>
                                <ButtonContainer>
                                    <Thumbnail src={store.thumbnail_url} alt={store.name} />
                                    <Button onClick={() => navigate(`/store/${id}/update`)}>정보 수정</Button>
                                </ButtonContainer>
                                <h1>{store.name}</h1>
                                <p>{store.address}</p>
                                <p>최소 주문 금액: {store.minimum_amount}원</p>
                                <p>배달 금액: {store.minimum_delivery_amount}원</p>
                                <p>전화번호: {store.phone_number}</p>
                                <p>별점: {renderStars(store.star)} ({store.star.toFixed(1)}점)</p>
                            </StoreInfo>
                        )}
                        <Tabs>
                            <Tab active={activeTab === 'menu'} onClick={() => setActiveTab('menu')}>메뉴</Tab>
                            <Tab active={activeTab === 'reviews'} onClick={() => {
                                setActiveTab('reviews');
                                fetchReviews();
                            }}>리뷰</Tab>
                        </Tabs>
                        {renderContent()}
                    </>
                )}
            </MainContent>
            <CartContainer>
                <h2>장바구니</h2>
                {cart.length === 0 ? (
                    <EmptyCartMessage>장바구니가 비어있습니다!</EmptyCartMessage>
                ) : (
                    <>
                        {cart.map((item, index) => (
                            <CartItem key={index}>
                                <CartItemName>{item.name}</CartItemName>
                                <CartItemPrice>{item.amount}원</CartItemPrice>
                                <RemoveButton onClick={() => removeFromCart(index)}>삭제</RemoveButton>
                            </CartItem>
                        ))}
                        <p>배달 비용: {store.minimum_delivery_amount}원</p>
                        <h3>총 금액: {totalAmount + store.minimum_delivery_amount}원</h3>
                        <Button onClick={handleCheckout}>결제하기</Button>
                    </>
                )}
            </CartContainer>
        </Container>
    );
}

export default StoreDetail;
