import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
`;

const FormContainer = styled.div`
    width: 80%;
    background: white;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const Message = styled.p`
    margin-top: 10px;
    color: ${props => (props.error ? 'red' : 'green')};
`;

function AddMenu() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [newMenuItem, setNewMenuItem] = useState({
        name: '',
        amount: '',
        thumbnail_url: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMenuItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                result: {
                    resultCode: 0,
                    resultMessage: '',
                    resultDescription: ''
                },
                body: {
                    store_id: id,
                    name: newMenuItem.name,
                    amount: newMenuItem.amount,
                    thumbnail_url: newMenuItem.thumbnail_url
                }
            };

            const response = await axios.post(process.env.REACT_APP_API_ROOT + `/open-api/store-menu/register`, requestBody);
            if (response.data.result.result_code === 200) {
                setMessage('메뉴가 성공적으로 추가되었습니다.');
                setError(false);
                setTimeout(() => navigate(`/store/${id}`), 2000);
            } else {
                setMessage('메뉴 추가에 실패했습니다.');
                setError(true);
            }
        } catch (error) {
            console.error('Error adding menu item:', error);
            setMessage('메뉴 추가 중 오류가 발생했습니다.');
            setError(true);
        }
    };

    return (
        <Container>
            <FormContainer>
                <h2>메뉴 추가</h2>
                <form onSubmit={handleSubmit}>
                    <Input
                        type="text"
                        name="name"
                        value={newMenuItem.name}
                        onChange={handleInputChange}
                        placeholder="메뉴 이름"
                        required
                    />
                    <Input
                        type="number"
                        name="amount"
                        value={newMenuItem.amount}
                        onChange={handleInputChange}
                        placeholder="가격"
                        required
                    />
                    <Input
                        type="text"
                        name="thumbnail_url"
                        value={newMenuItem.thumbnail_url}
                        onChange={handleInputChange}
                        placeholder="썸네일 URL"
                        required
                    />
                    <Button type="submit">메뉴 추가</Button>
                </form>
                {message && <Message error={error}>{message}</Message>}
            </FormContainer>
        </Container>
    );
}

export default AddMenu;
