import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
    height: 140vh;
`;

const Button = styled.button`
    position: relative;
    margin: 10px;
    padding: 30px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const CategoryButton = styled(Button)`
    width: 200px; 
    height: 150px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4열로 배치 */
    gap: 20px;
    justify-items: center;
    margin-bottom: 40px; 
`;

const CategoryImage = styled.img`
    width: 70px; 
    height: 70px;
    margin-bottom: 10px;
`;

const SuggestedStoreContainer = styled.div`
    margin-top: 80px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 60%;
`;

const Thumbnail = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-bottom: 10px;
`;

const StoreDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function Home() {
    const isLoggedIn = !!localStorage.getItem('access_token'); // 로그인 상태 확인
    const navigate = useNavigate();
    const [userName, setUserName] = useState(localStorage.getItem('user_name') || '');
    const [suggestedStore, setSuggestedStore] = useState(null);

    useEffect(() => {
        const fetchAndStoreUserInfo = async () => {
            try {
                const email = localStorage.getItem('user_email');
                if (!email || !isLoggedIn) {
                    return;
                }

                const encodedEmail = encodeURIComponent(email);
                console.log(`Fetching user info for encoded email: ${encodedEmail}`); // 디버깅을 위한 콘솔 출력

                const token = localStorage.getItem('access_token');
                const response = await axios.get(process.env.REACT_APP_HOME_URL + `/api/user/me?email=${encodedEmail}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const userInfo = response.data.body;
                localStorage.setItem('user_id', userInfo.id);
                localStorage.setItem('user_name', userInfo.name);
                localStorage.setItem('user_address', userInfo.address);
                setUserName(userInfo.name); // 상태 업데이트
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        const fetchStores = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_ROOT + `/api/store/list`);
                if (response.data.result.result_code === 200) {
                    const stores = response.data.body;
                    if (stores.length > 0) {
                        const randomStore = stores[Math.floor(Math.random() * stores.length)];
                        setSuggestedStore(randomStore);
                    }
                } else {
                    console.error('Failed to fetch stores');
                }
            } catch (error) {
                console.error('Error fetching stores:', error);
            }
        };

        if (isLoggedIn) {
            fetchAndStoreUserInfo();
            fetchStores();
        }
    }, [isLoggedIn]);

    const categories = [
        { value: 'CHINESE_FOOD', label: '중식', imageUrl: 'https://i.imgur.com/qH8I483.png' },
        { value: 'WESTERN_FOOD', label: '양식', imageUrl: 'https://i.imgur.com/de3p4LF.png' },
        { value: 'KOREAN_FOOD', label: '한식', imageUrl: 'https://i.imgur.com/coXKObo.png' },
        { value: 'JAPANESE_FOOD', label: '일식', imageUrl: 'https://i.imgur.com/EwhtMlG.png' },
        { value: 'CHICKEN', label: '치킨', imageUrl: 'https://i.imgur.com/eAikKri.png' },
        { value: 'PIZZA', label: '피자', imageUrl: 'https://i.imgur.com/BSBIdlh.png' },
        { value: 'HAMBURGER', label: '햄버거', imageUrl: 'https://i.imgur.com/8AB9f1b.png' },
        { value: 'COFFEE_TEA', label: '카페', imageUrl: 'https://i.imgur.com/6nwKTtQ.png' }
    ];

    if (!isLoggedIn) {
        return (
            <Container>
                <h1>반갑습니다!</h1>
                <Button onClick={() => navigate('/login')}>로그인</Button>
                <Button onClick={() => navigate('/register')}>회원가입</Button>
            </Container>
        );
    }

    return (
        <Container>
            <h1>어서오세요 {userName}님!</h1>
            <ButtonContainer>
                {categories.map(category => (
                    <CategoryButton
                        key={category.value}
                        onClick={() => navigate(`/store-list?category=${category.value}`)}
                    >
                        <CategoryImage src={category.imageUrl} alt={category.label} />
                        {category.label}
                    </CategoryButton>
                ))}
            </ButtonContainer>
            {suggestedStore && (
                <SuggestedStoreContainer>
                    <h2>여기서 먹어보는건 어떄요?</h2>
                    <Thumbnail src={suggestedStore.thumbnail_url} alt={suggestedStore.name} />
                    <StoreDetails>
                        <h3>{suggestedStore.name}</h3>
                        <p>{suggestedStore.address}</p>
                    </StoreDetails>
                    <Button onClick={() => navigate(`/store/${suggestedStore.id}`)}>자세히 보기</Button>
                </SuggestedStoreContainer>
            )}
        </Container>
    );
}

export default Home;
