import React from 'react';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import StoreRegister from './components/StoreRegister';
import StoreList from './components/StoreList';
import StoreDetail from './components/StoreDetail';
import AddMenu from './components/AddMenu';
import UserInfo from './components/UserInfo';
import UpdateStore from './components/UpdateStore';
import Checkout from './components/Checkout';
import UserReviews from './components/UserReviews';
import { CartProvider } from './context/CartContext';

const Nav = styled.nav`
    background-color: #444;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NavGroup = styled.div`
    display: flex;
    align-items: center;
`;

const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    margin: 0 10px;
    padding: 5px 10px;
    border-radius: 5px;
    //background-color: #

    &:hover {
        background-color: #555;
    }
`;

const LogoutButton = styled.button`
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ff4d4d;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ff1a1a;
    }
`;

function App() {
    const isLoggedIn = !!localStorage.getItem('access_token');

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/login';
    };

    return (
        <CartProvider>
            <div>
                <Nav>
                    <NavGroup>
                        <StyledLink to="/">메인으로</StyledLink>
                        {isLoggedIn && <StyledLink to="/store-register">가게 등록</StyledLink>}
                        {isLoggedIn && <StyledLink to="/store-list">가게 목록</StyledLink>}
                    </NavGroup>
                    <NavGroup>
                        {isLoggedIn && <StyledLink to="/user-info">유저 정보</StyledLink>}
                        {isLoggedIn && <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>}
                    </NavGroup>
                </Nav>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
                    <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <Register />} />
                    <Route path="/store-register" element={isLoggedIn ? <StoreRegister /> : <Navigate to="/login" />} />
                    <Route path="/store-list" element={isLoggedIn ? <StoreList /> : <Navigate to="/login" />} />
                    <Route path="/store/:id" element={isLoggedIn ? <StoreDetail /> : <Navigate to="/login" />} />
                    <Route path="/store/:id/add-menu" element={isLoggedIn ? <AddMenu /> : <Navigate to="/login" />} />
                    <Route path="/user-info" element={isLoggedIn ? <UserInfo /> : <Navigate to="/login" />} />
                    <Route path="/store/:id/update" element={isLoggedIn ? <UpdateStore /> : <Navigate to="/login" />} />
                    <Route path="/checkout" element={isLoggedIn ? <Checkout /> : <Navigate to="/login" />} />
                    <Route path="/user-reviews/:userId" element={isLoggedIn ? <UserReviews /> : <Navigate to="/login" />} />
                </Routes>
            </div>
        </CartProvider>
    );
}

export default App;
