import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;

    &:hover {
        background-color: #0056b3;
    }
`;

const Message = styled.p`
    margin-top: 10px;
    color: red;
`;

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const requestBody = {
            result: {
                resultCode: 0,
                resultMessage: '',
                resultDescription: ''
            },
            body: {
                email,
                password
            }
        };

        axios.post(process.env.REACT_APP_API_ROOT + '/open-api/user/login', requestBody)
            .then(response => {
                const { access_token, refresh_token } = response.data.body;
                if (access_token) {
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('refresh_token', refresh_token);
                    localStorage.setItem('user_email', email);
                    window.location.href = '/';
                } else {
                    setErrorMessage('로그인 토큰을 받을 수 없습니다.');
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.result) {
                    const { result_message } = error.response.data.result;
                    setErrorMessage(result_message);
                } else {
                    setErrorMessage('로그인 중 오류가 발생했습니다.');
                }
            });
    };

    return (
        <Container>
            <h1>Login</h1>
            <Form onSubmit={handleSubmit}>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일" required />
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호" required />
                <Button type="submit">로그인</Button>
                <Button type="button" onClick={() => window.location.href = '/register'}>회원가입</Button>
            </Form>
            {errorMessage && <Message>{errorMessage}</Message>}
        </Container>
    );
}

export default Login;
