import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const addToCart = (item) => {
        setCart((prevCart) => [...prevCart, item]);
    };

    const removeFromCart = (index) => {
        setCart((prevCart) => prevCart.filter((_, i) => i !== index));
    };

    const totalAmount = cart.reduce((sum, item) => sum + item.amount, 0);

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, totalAmount }}>
            {children}
        </CartContext.Provider>
    );
};
