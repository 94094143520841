import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const Message = styled.p`
    margin-top: 10px;
    color: ${props => (props.error ? 'red' : 'green')};
`;

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const requestBody = {
            result: {
                resultCode: 0,
                resultMessage: '',
                resultDescription: ''
            },
            body: {
                name,
                email,
                address,
                password
            }
        };

        axios.post(process.env.REACT_APP_API_ROOT + `/open-api/user/register`, requestBody)
            .then(response => {
                console.log(response.data);
                    setSuccessMessage('회원가입이 성공적으로 완료되었습니다!');
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
            })
            .catch(error => {
                console.error('Error:', error);
                setErrorMessage('이미 등록된 이메일입니다.');
            });
    };

    return (
        <Container>
            <h1>Register</h1>
            <Form onSubmit={handleSubmit}>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="이름" required />
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="이메일" required />
                <Input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="주소" required />
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호" required />
                <Button type="submit">회원가입</Button>
            </Form>
            {errorMessage && <Message error>{errorMessage}</Message>}
            {successMessage && <Message>{successMessage}</Message>}
        </Container>
    );
}

export default Register;
